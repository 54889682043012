// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-page-tsx": () => import("./../../../src/pages_/404/404.page.tsx" /* webpackChunkName: "component---src-pages-404-404-page-tsx" */),
  "component---src-pages-410-410-page-tsx": () => import("./../../../src/pages_/410/410.page.tsx" /* webpackChunkName: "component---src-pages-410-410-page-tsx" */),
  "component---src-pages-about-new-about-page-tsx": () => import("./../../../src/pages_/about/new-about.page.tsx" /* webpackChunkName: "component---src-pages-about-new-about-page-tsx" */),
  "component---src-pages-blog-new-blog-page-tsx": () => import("./../../../src/pages_/blog/new-blog.page.tsx" /* webpackChunkName: "component---src-pages-blog-new-blog-page-tsx" */),
  "component---src-pages-blog-single-blog-single-page-tsx": () => import("./../../../src/pages_/blog/single/blog-single.page.tsx" /* webpackChunkName: "component---src-pages-blog-single-blog-single-page-tsx" */),
  "component---src-pages-bundle-details-new-bundle-details-page-tsx": () => import("./../../../src/pages_/bundle-details/new-bundle-details-page.tsx" /* webpackChunkName: "component---src-pages-bundle-details-new-bundle-details-page-tsx" */),
  "component---src-pages-career-career-page-tsx": () => import("./../../../src/pages_/career/career.page.tsx" /* webpackChunkName: "component---src-pages-career-career-page-tsx" */),
  "component---src-pages-career-positions-backend-developer-tsx": () => import("./../../../src/pages_/career/positions/backend-developer.tsx" /* webpackChunkName: "component---src-pages-career-positions-backend-developer-tsx" */),
  "component---src-pages-career-positions-content-writer-tsx": () => import("./../../../src/pages_/career/positions/content-writer.tsx" /* webpackChunkName: "component---src-pages-career-positions-content-writer-tsx" */),
  "component---src-pages-career-positions-digital-marketing-manager-tsx": () => import("./../../../src/pages_/career/positions/digital-marketing-manager.tsx" /* webpackChunkName: "component---src-pages-career-positions-digital-marketing-manager-tsx" */),
  "component---src-pages-career-positions-frontend-developer-tsx": () => import("./../../../src/pages_/career/positions/frontend-developer.tsx" /* webpackChunkName: "component---src-pages-career-positions-frontend-developer-tsx" */),
  "component---src-pages-career-positions-nodejs-developer-tsx": () => import("./../../../src/pages_/career/positions/nodejs-developer.tsx" /* webpackChunkName: "component---src-pages-career-positions-nodejs-developer-tsx" */),
  "component---src-pages-career-positions-product-developer-tsx": () => import("./../../../src/pages_/career/positions/product-developer.tsx" /* webpackChunkName: "component---src-pages-career-positions-product-developer-tsx" */),
  "component---src-pages-career-positions-worker-op-center-bg-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-bg.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-bg-tsx" */),
  "component---src-pages-career-positions-worker-op-center-cs-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-cs.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-cs-tsx" */),
  "component---src-pages-career-positions-worker-op-center-general-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-general.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-general-tsx" */),
  "component---src-pages-career-positions-worker-op-center-gr-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-gr.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-gr-tsx" */),
  "component---src-pages-career-positions-worker-op-center-pl-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-pl.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-pl-tsx" */),
  "component---src-pages-career-positions-worker-op-center-ro-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-ro.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-ro-tsx" */),
  "component---src-pages-career-positions-worker-op-center-sk-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-sk.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-sk-tsx" */),
  "component---src-pages-career-positions-worker-op-center-sl-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-sl.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-sl-tsx" */),
  "component---src-pages-career-positions-worker-op-center-sq-tsx": () => import("./../../../src/pages_/career/positions/worker-op-center-sq.tsx" /* webpackChunkName: "component---src-pages-career-positions-worker-op-center-sq-tsx" */),
  "component---src-pages-codex-codex-page-tsx": () => import("./../../../src/pages_/codex/codex.page.tsx" /* webpackChunkName: "component---src-pages-codex-codex-page-tsx" */),
  "component---src-pages-faq-faq-page-tsx": () => import("./../../../src/pages_/faq/faq.page.tsx" /* webpackChunkName: "component---src-pages-faq-faq-page-tsx" */),
  "component---src-pages-home-home-page-tsx": () => import("./../../../src/pages_/home/home.page.tsx" /* webpackChunkName: "component---src-pages-home-home-page-tsx" */),
  "component---src-pages-landings-a-1-landing-page-a-1-landing-page-page-tsx": () => import("./../../../src/pages_/landings/a1LandingPage/a1LandingPage.page.tsx" /* webpackChunkName: "component---src-pages-landings-a-1-landing-page-a-1-landing-page-page-tsx" */),
  "component---src-pages-landings-nikana-new-landing-page-nikana-new-landing-page-tsx": () => import("./../../../src/pages_/landings/nikanaNewLandingPage/nikanaNewLandingPage.tsx" /* webpackChunkName: "component---src-pages-landings-nikana-new-landing-page-nikana-new-landing-page-tsx" */),
  "component---src-pages-landings-snnp-landing-page-snnp-landing-page-page-tsx": () => import("./../../../src/pages_/landings/snnpLandingPage/snnpLandingPage.page.tsx" /* webpackChunkName: "component---src-pages-landings-snnp-landing-page-snnp-landing-page-page-tsx" */),
  "component---src-pages-news-news-page-tsx": () => import("./../../../src/pages_/news/news.page.tsx" /* webpackChunkName: "component---src-pages-news-news-page-tsx" */),
  "component---src-pages-partnership-new-partnership-page-tsx": () => import("./../../../src/pages_/partnership/new-partnership.page.tsx" /* webpackChunkName: "component---src-pages-partnership-new-partnership-page-tsx" */),
  "component---src-pages-personal-rights-personal-rights-page-tsx": () => import("./../../../src/pages_/personal-rights/personal-rights.page.tsx" /* webpackChunkName: "component---src-pages-personal-rights-personal-rights-page-tsx" */),
  "component---src-pages-promo-promo-page-tsx": () => import("./../../../src/pages_/promo/promo.page.tsx" /* webpackChunkName: "component---src-pages-promo-promo-page-tsx" */),
  "component---src-pages-temp-temp-page-tsx": () => import("./../../../src/pages_/temp/temp.page.tsx" /* webpackChunkName: "component---src-pages-temp-temp-page-tsx" */),
  "component---src-pages-terms-terms-page-tsx": () => import("./../../../src/pages_/terms/terms.page.tsx" /* webpackChunkName: "component---src-pages-terms-terms-page-tsx" */),
  "component---src-pages-terms-terms-pdf-page-tsx": () => import("./../../../src/pages_/terms/termsPDF.page.tsx" /* webpackChunkName: "component---src-pages-terms-terms-pdf-page-tsx" */),
  "component---src-pages-test-video-test-video-page-tsx": () => import("./../../../src/pages_/testVideo/test-video-page.tsx" /* webpackChunkName: "component---src-pages-test-video-test-video-page-tsx" */),
  "component---src-pages-unsubscribe-unsubscribe-page-tsx": () => import("./../../../src/pages_/unsubscribe/unsubscribe.page.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-unsubscribe-page-tsx" */),
  "component---src-pages-webshop-pages-purchase-final-allsecure-tsx": () => import("./../../../src/pages_/webshop/pages/purchase-final-allsecure.tsx" /* webpackChunkName: "component---src-pages-webshop-pages-purchase-final-allsecure-tsx" */),
  "component---src-pages-webshop-pages-purchase-final-payslip-tsx": () => import("./../../../src/pages_/webshop/pages/purchase-final-payslip.tsx" /* webpackChunkName: "component---src-pages-webshop-pages-purchase-final-payslip-tsx" */),
  "component---src-pages-webshop-pages-purchase-page-tsx": () => import("./../../../src/pages_/webshop/pages/purchase-page.tsx" /* webpackChunkName: "component---src-pages-webshop-pages-purchase-page-tsx" */)
}

